import React from 'react'
import Layout from '../components/Layout'
import PageHero from '../components/PageHero/PageHero'
import List from '../components/List/List'
import ListItem from '../components/List/ListItem'
import { graphql } from 'gatsby'
import Spacer from '../components/Spacer'
import Seo from '../components/Seo'

const oppettider = ({ data: { page } }) => {
  const image = page.hero.bild
  const oppettider = page.oppettider.oppettider
  return (
    <Layout>
      <Seo title={'Öppettider'} description={'Våra öppetider i Åkersberga'} />
      <PageHero title={'Öppettider'} src={image} />
      <div className="container ">
        <div className="mx-auto max-w-3xl">
          <List>
            {oppettider?.length &&
              oppettider.map((item, index) => (
                <ListItem key={index} first={item.dag} second={item.tid} />
              ))}
          </List>
        </div>
        <Spacer />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query OpeningPage {
    page: wpPage(title: { eq: "Öppettider" }) {
      id
      oppettider {
        oppettider {
          dag
          tid
        }
      }
      hero {
        bild {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default oppettider
